import molekule from "./showcase_Molekule.png";
import seeker from "./showcase_seeker.png";
import rh from "./showcase_RH.png";
import ebay from "./showcase_eBay.png";
import nook from "./showcase_nook.png"
import matterport from "./showcase_Matterport.png";
import logo_ebay from "./client_eBay.png";
import logo_box from "./client_Box.png";
import logo_sf from "./client_StitchFix.png";
import logo_rolex from "./client_Rolex.png";
import logo_canary from "./client_Canary.png";
import logo_disney from "./client_Disney.png";
import logo_molekule from "./client_Molekule.png";
import logo_conde from "./client_CondeNast.png";
import logo_wb from "./client_WB.png";
import logo_discovery from "./client_Discovery.png";
import logo_rh from "./client_RH.png";
import logo_hp from "./client_HP.png";

import {ReactComponent as Arrow} from './upandtotheright.svg';
import {ReactComponent as Longarrow} from './longarrow.svg';
import {ReactComponent as Logo} from './logo.svg';
import {ReactComponent as Buttonarrow} from './email_arrow.svg';
import './App.css';

function Logos(){
  let logoAssets = [
    {src: logo_ebay, alt: "eBay"},
    {src: logo_hp, alt: "HP"},
    {src: logo_rh, alt: "RH"},
    {src: logo_discovery, alt: "Discovery"},
    {src: logo_wb, alt: "WB"},
    {src: logo_conde, alt: "Condé Nast"},
    {src: logo_molekule, alt: "Molekule"},
    {src: logo_disney, alt: "The Walt Disney Company"},
    {src: logo_canary, alt: "Canary"},
    {src: logo_rolex, alt: "Rolex"},
    {src: logo_sf, alt: "Stitch Fix"},
    {src: logo_box, alt: "Box"}
  ];
  return(
    <div className="logowall">
      { logoAssets.map((la:any, i:number) => <img key={ "img" + i } src={la.src} alt={la.alt} />) }
    </div>
  )
}

function Showcase(){

  return (
    <></>
  );

  let showcase = [
    {img: molekule, name: "Molekule"},
    {img: seeker, name: "Seeker"},
    {img: rh, name: "RH"},
    {img: ebay, name: "eBay"},
    {img: nook, name: "Nook"},
    {img: matterport, name: "Matterport"}
  ];
  return (
    <section className="floodImages">
      { showcase.map((sc:any, i:number) => <div className="container" key={ "showcase" + i}>
        <div className="img" style={{ backgroundImage: `url(${sc.img})` }}></div>
        <div className="title">{sc.name}</div>
      </div>)}
    </section>
  )
}

function Versiontwo() {
  return (
    <div className="App" id="top">
      <section className="blackForeground opening">
        <div className="padTopBig">
          <h2 className="upper">Lead<br />Through<br />Change.</h2>
        </div>
      </section>
      <section className="blackForeground opening">
        <div className="padTopMedium">
          <h4 id="hook">We design<br />organizations<br />to <span id="update">move</span><br />at the speed<br />of the market.</h4>
        </div>
      </section>
      <section className="hero above">
        <div><Logo className="white" /></div>
      </section>
      <section className="threeColumn textBlocks padAround above" id="services">
        <div>
          <h5>Business<br />Evolution</h5>
          <p>The market is shifting constantly with new innovations, emerging competition and the most fluid consumer behavior ever measured. The speed of the market is many multiples over the speed of any organization — you’ll never have time to restructure.</p>
          <p className="hidden">We staff senior executives to deliver results that match the speed of the market and bring all of your teams along on the journey.</p>
          <ul className="services">
            <li>Transformation Planning</li>
            <li>Ecosystem Planning</li>
            <li>New Service Development</li>
          </ul>
        </div>
        <div>
          <h5>Positioning & Brand Transformation</h5>
          <p>We’ve spent the last two decades reimagining the possibilities for the businesses we’ve worked for, going deep with founders and business leaders to understand the market, the competitive set, the constantly shifting needs of consumers and buyers and the megatrends impacting each category.</p>
          <p className="hidden">We build blueprints for differentiation, and roadmaps that embed the positioning of the brand and product into every layer of the organization.</p>
          <ul className="services">
            <li>Brand Strategy</li>
            <li>Adaptive Design Systems</li>
            <li>Brand Tools & Governance</li>
            <li>Campaigns & Activation</li>
          </ul>
        </div>
        <div>
          <h5>Product<br />Innovation</h5>
          <p>We go beyond words and ideas, building and delivering breakthrough products that drive demand and desire. Whether we’re working with internal teams to build the infrastructure and customer journeys, or taking the responsibility on ourselves, we take a rapid approach to blending insights into prototypes to quickly refine, build and launch the next big thing.</p>
          <ul className="services">
            <li>Product Strategy</li>
            <li>Prototyping & Build</li>
            <li>Go-To-Market Strategy & Activation</li>
          </ul>
        </div>
      </section>
      <section className="whiteForeground padAround above">
        <h3 className="center padTop">This is Pilot</h3>
        <h6>We started Pilot because as clients we became frustrated and disappointed with the deliverables of traditional consultants and agencies. We need to move faster, do more and bring organizations along on a journey of transformation from the inside and from the outside.</h6>
      </section>
      <section className="padAround floodWhite" id="casestudies">
        <h3 className="center padTop">What We’ve Piloted</h3>
        <p>We have been clients at Condé Nast, Hewlett Packard, Box, Stitch Fix, Nuance, RH and eBay. We helped grow startups such as Molekule, Seeker (Group9), SickSports, Matternet, Canary and Matterport. We’ve built businesses, repositioned products and crafted long-range product plans for market leaders including the Aston Martin Rapide, Tile, Levis 501, EA Access, L’Oreal, MSLO.</p>
        <Logos />
      </section>
      <section id="about" className="whiteForeground">
        <h3 className="center padCap padSides">Founding Pilots</h3>
      </section>
      <section className="twoColumn whiteForeground">
        <div className="founder">
          <h5>Marc Shillum</h5>
          <p>Marc formed his career at successive cusps of massive change across many mediums. Beginning with democratization of computer animation, followed closely by the digitization of news media, the decentralization of advertising, the development of service design, the definition of logistics for autonomous transportation and product planning for additive manufacturing – all underscored by an intuitive sense for Brand Development within the digital age.</p>
        </div>
        <div className="founder">
          <h5>Greg Johnson</h5>
          <p>Greg sits at the intersection of brand strategy, digital transformation and new business innovation. With a focus on digital since the early 90s — through publishing, ecommerce, new product development and active branding campaigns and experiences — his catalog of work shows a constant quest to unearth the value of each brand’s equities in a constantly changing market. </p>
        </div>
        <div className="diagonal">
          <Longarrow className="white" />
        </div>
      </section>
      <section className="floodGrey contactus">
        <h3 className="padTopBig">Let’s Pilot</h3>
        <a href="mailto:info@pilotsofchange.xyz" className="lets">info@pilotsofchange.xyz</a>
        <a href="mailto:info@pilotsofchange.xyz"><Buttonarrow className="button" /></a>
      </section>



      <div className="cssArrowHolder">
        <div className="cssArrow"></div>
        <div className="cssArrowStem"></div>
      </div>


      <nav>
        <ul>
          <li className="clickchange" data-click="about"><a href="#about">About</a></li>
          <li className="clickchange" data-click="services"><a href="#services">Services</a></li>
          <li className="clickchange" data-click="casestudies"><a href="#casestudies">Case Studies</a></li>
          <li className="extra"><a href="mailto:join@pilotsofchange.xyz">Become a Pilot</a></li>
          <li><a href="mailto:info@pilotsofchange.xyz">Hire a Pilot</a></li>
        </ul>
        <a href="#top"><Logo className="white" /></a>
      </nav>
    </div>
  );
}

export default Versiontwo;
